<template>
  <div class="layout layout--private">
    <header class="layout__header">
      <div class="k-link" @click="$router.go()">
        <icon-logo-header />
      </div>
      <div class="layout__header--options">
        <span class="last__update pe-2"
          >Last data update time : {{ convertDate(revisionDate) }} SGT</span
        >
        <div class="header-icon">
          <template v-if="showManageSubscriptionPageLink">
            <k-button
              class="m-2"
              theme-color="secondary"
              fill-mode="outline"
              @click="showNavigationPopup = !showNavigationPopup"
              ref="button"
              v-click-outside="closeNavigationPopup"
            >
              <span>Subscription</span>
              <span
                class="k-icon k-panelbar-toggle k-panelbar-expand k-i-arrow-chevron-down"
              />
            </k-button>
            <k-popup
              :anchor="'button'"
              :show="showNavigationPopup"
              :popup-class="'popup-content'"
            >
              <k-button
                class="m-2"
                theme-color="secondary"
                fill-mode="outline"
                @click="$router.push('/manageUsers')"
              >
                Manage Subscription
              </k-button>
              <k-button
                class="m-2"
                theme-color="secondary"
                fill-mode="outline"
                @click="openSubscriptionDetailDialog"
                v-if="!isUserUsingTrialSubscription"
              >
                Subscription Details
              </k-button>
            </k-popup>
          </template>
          <k-button
            theme-color="secondary"
            fill-mode="outline"
            @click="checkIfItPossibleToShareView"
          >
            Share View
          </k-button>
          <k-button
            class="custom-button--full-width"
            theme-color="secondary"
            fill-mode="outline"
            @click="contactDialog = true"
            >Get Support</k-button
          >
          <k-button
            class="custom-button--full-width"
            theme-color="secondary"
            fill-mode="outline"
            @click="createIssueTicketDialog = true"
            v-if="$environment !== 'production'"
            >Report an issue</k-button
          >
          <k-button
            class="m-2"
            theme-color="secondary"
            fill-mode="outline"
            @click="$router.push('/manager/tenant')"
          >
            Manage Users
          </k-button>
          <k-button
            class="custom-button"
            theme-color="secondary"
            fill-mode="link"
            rounded="full"
            @click="drawerVisible = true"
            ><icon-account
          /></k-button>
        </div>
        <subscription-detail-dialog ref="subscriptionDetailDialog" />
      </div>
    </header>
    <div
      class="right-drawer"
      :style="{
        width: drawerVisible ? '243px' : '0'
      }"
    >
      <div class="list-group text-end">
        <button class="list-group-item mb-4">
          <div class="row">
            <div class="col-2 ps-0">
              <a class="k-button-link" @click="drawerVisible = false"
                ><icon-close></icon-close
              ></a>
            </div>
            <div class="col-7 px-0 text-end text-truncate">
              <span v-text="$store.state.principal.name"></span>
            </div>
            <div class="col-3"><icon-account></icon-account></div>
          </div>
        </button>
        <button
          @click="userDialog = true"
          class="list-group-item list-group-item-action"
        >
          User Details
        </button>
        <button
          @click="updatePasswordDialog = true"
          class="list-group-item list-group-item-action"
        >
          Update Password
        </button>
        <button
          @click="logoutDialog = true"
          class="list-group-item list-group-item-action"
        >
          Logout
        </button>
      </div>
    </div>
    <!-- We will make the mask fill the screen while the menu is visible. Because its z-index is 1 less than that of the menu, the menu will still be displayed on top of it -->
    <div
      class="drawer-mask"
      :style="{
        width: drawerVisible ? '100vw' : '0',
        opacity: drawerVisible ? '0.6' : '0'
      }"
      @click="drawerVisible = false"
    ></div>
    <div class="layout__sidebar filter-bar">
      <filter-side-bar ref="filterSideBar"></filter-side-bar>
    </div>
    <div class="layout__body bg-white">
      <router-view></router-view>
    </div>
    <k-dialog v-if="contactDialog" :title="' '" @close="contactDialog = false">
      <div class="rounded-2 p-3">
        <div v-html="contactAdmin"></div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="createIssueTicketDialog"
      :title="' '"
      @close="createIssueTicketDialog = false"
    >
      <div class="rounded-2 p-3">
        <create-issue-ticket />
      </div>
    </k-dialog>
    <k-dialog v-if="logoutDialog" :title="' '" @close="logoutDialog = false">
      <div class="row">
        <div class="col-12">
          <p class="title d-flex justify-content-center">
            Log out of Transparently.AI
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-2 d-flex justify-content-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="logoutDialog = false"
            >Cancel</k-button
          >
          <k-button theme-color="primary" @click="logout">Finish</k-button>
        </div>
      </div>
    </k-dialog>
    <k-dialog v-if="userDialog" :title="' '" @close="userDialog = false">
      <div class="row">
        <div class="col-12">
          <div class="title d-flex justify-content-center">Your details</div>
          <div class="content py-3 d-flex justify-content-center">
            Contact your administrator to update your details
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 area">
          <div class="row">
            <div
              class="col-12 content d-flex justify-content-center"
              v-html="$store.state.principal.name"
            ></div>
          </div>
          <div class="row">
            <div
              class="col-12 content d-flex justify-content-center"
              v-html="$store.state.principal.email"
            ></div>
          </div>
          <div class="row">
            <div
              class="col-12 content d-flex justify-content-center"
              v-html="$store.state.principal.phone"
            ></div>
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-12 py-2 d-flex justify-content-center">
          <k-button class="me-2" fill-mode="outline" @click="userDialog = false"
            >Close</k-button
          >
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="updatePasswordDialog"
      :title="' '"
      @close="updatePasswordDialog = false"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p class="title mb-2 d-flex justify-content-center">
            Update Password
          </p>
          <span class="content gray d-flex justify-content-center"
            >Send password reset link to registered email address</span
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="updatePasswordDialog = false"
            >Cancel</k-button
          >
          <k-button theme-color="primary" @click="sendLinkChangePassword"
            >Send</k-button
          >
        </div>
      </div>
    </k-dialog>
    <splash-screen
      v-if="$store.state.splashScreen"
      :isLoading="$store.state.splashScreen"
    />
    <k-dialog v-if="showSL" :title="' '" @close="showSL = false">
      <div class="row mb-3">
        <div class="col-12">
          <div class="title d-flex justify-content-center">Share View</div>
          <div class="pt-2 content d-flex justify-content-center text-center">
            Registered users will be able to view the shared view from this
            link. Press ‘Copy’ below, and then use the paste function on your
            mouse/keyboard to copy the link into a message to another registered
            Transparently.AI user.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pb-2 d-flex justify-content-center">
          <textarea
            v-model="shareLink"
            id="sharelinkarea"
            name="sharelinkarea"
            style="width: 100%"
            ref="sharelinkarea"
            class="textarea"
          ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-3 d-flex justify-content-center">
          <k-button class="me-2" fill-mode="outline" @click="showSL = false"
            >Cancel</k-button
          >
          <k-button
            class="me-2"
            theme-color="primary"
            @click="copyLink"
            v-if="!linkCopied"
            >Copy</k-button
          >
          <k-button class="me-2" theme-color="success" v-else>Copied!</k-button>
        </div>
      </div>
    </k-dialog>
    <k-dialog v-if="announcement == true" class="announcement-dialog">
      <div v-for="(item, index) in announcementContent" :key="index">
        <div class="announcement" v-if="item.content">
          <div class="row pt-1">
            <div
              class="col-10 announcement-content"
              v-html="item.content"
            ></div>
            <div
              class="col-2 px-0 d-flex justify-content-center align-items-end"
            >
              <k-button @click="announcementValidation(item)"
                >Acknowledge</k-button
              >
            </div>
          </div>
        </div>
      </div>
    </k-dialog>

    <!-- Warning message when user click shareview button with no selected ticker ID available -->
    <k-dialog
      v-if="forbiddenToShareView"
      :title="' '"
      @close="forbiddenToShareView = false"
    >
      <div class="row">
        <div class="col-12">
          <!-- Updated based on Peter's suggestion and approved by the client -->
          <div class="text-center pb-4">
            <icon-warning class="text-warning" />
          </div>
          <p class="text-center">
            Stock not selected. Before you share a view, you need to select one
            stock to display in the main report.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-2 d-flex justify-content-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="forbiddenToShareView = false"
            >Cancel</k-button
          >
        </div>
      </div>
    </k-dialog>

    <!-- Warning message when user click shareview button when filter not applied -->
    <k-dialog v-if="warningFilter" :title="' '" @close="warningFilter = false">
      <div class="row">
        <div class="col-12">
          <!-- Updated based on Peter's suggestion and approved by the client -->
          <div class="text-center pb-4">
            <icon-warning class="text-warning" />
          </div>
          <p class="text-center">
            Share View needs you to apply the filter to display the filter
            results.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-2 d-flex justify-content-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="warningFilter = false"
            >Cancel</k-button
          >
        </div>
      </div>
    </k-dialog>

    <v-idle class="d-none" @idle="logout" :loop="true" :duration="idleTime" />
  </div>
</template>

<script>
import { EventBus } from '../../main.js';
import compFilterSideBar from './userDynamicFilterSideBar.vue';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import CreateIssueTicket from '../dialog/CreateIssueTicket/CreateIssueTicket';
import SubscriptionDetailDialog from '@/components/tenantAdmin/components/SubscriptionDetailDialog/SubscriptionDetailDialog.vue';
import getContactAdminMixin from '@/components/setting/mixins/contactAdmin';
import userSubscriptionMixin from '@/components/tenantAdmin/mixins/userSubscription';

export default {
  components: {
    'filter-side-bar': compFilterSideBar,
    CreateIssueTicket,
    SubscriptionDetailDialog
  },
  mixins: [getContactAdminMixin, userSubscriptionMixin],
  data() {
    return {
      drawerVisible: false,
      announcement: false,
      announcementContent: [{ title: null, content: null }],
      contactDialog: false,
      userDialog: false,
      createIssueTicketDialog: false,
      logoutDialog: false,
      updatePasswordDialog: false,
      showSL: false,
      shareLink: null,
      linkCopied: false,
      announcementSize: 0,
      idleTime: 3600 * 12, // initialize idle time (12 hours)
      shareViewAvailable: true,
      forbiddenToShareView: false,
      filterNotApplied: false,
      warningFilter: false,
      revisionDate: null,
      showNavigationPopup: false
      // revisionDate accepted format
      // -> DateTime "2022-08-15T11:35:00"
      // -> RAW "2022-08-15T04:35:00.000+00:00",
      // -> null (if invalid date format from bq, then backend should send -> null)
    };
  },
  computed: {
    showManageSubscriptionPageLink() {
      return this.$store.state.principal.tenantAdministrator;
    }
  },
  created() {
    let tenantId = this.$store.state.principal.tenantId;

    if (this.$route.query.share != null) {
      if (tenantId != null) {
        try {
          let share = CryptoJS.AES.decrypt(
            this.url_decode(this.$route.query.share),
            tenantId
          ).toString(CryptoJS.enc.Utf8);
          let params = share.split('&');

          if (share.includes('tenantId=' + tenantId)) {
            this.$store.commit(
              'fillShareTicker',
              decodeURI(params[0].split('=')[1])
            );
            this.$store.commit(
              'fillShareYear',
              decodeURI(params[1].split('=')[1])
            );
            this.$store.commit(
              'fillShareFilter',
              JSON.parse(decodeURIComponent(params[2].split('=')[1]))
            );
            this.$store.commit(
              'fillShareKeyword',
              decodeURI(params[3].split('=')[1])
            );
            this.$store.commit(
              'fillShareRegion',
              decodeURI(params[5].split('=')[1])
            );
            if (params[6]) {
              this.$store.commit(
                'fillShareRegionName',
                decodeURI(params[6].split('=')[1])
              );
            }
            this.$store.commit('updateShareStatus', true);
          } else {
            this.$router.push('/dashboard');
          }
        } catch (error) {
          this.$router.push('/dashboard');
        }
      }
    }
  },
  mounted() {
    // Check is it possible to shareview when filter not applied
    EventBus.$on('filterNotApplied', (bol) => {
      this.filterNotApplied = bol;
    });
    // Check is it possible to shareview when main report N/A
    EventBus.$on('ticker', (ticker) => {
      this.shareViewAvailable = ticker.available;
    });
    /* Splash Screen config - Begin*/
    setTimeout(() => {
      this.$store.state.splashScreen = false;
    }, 3000);
    /* Splash Screen config - End*/
    if (
      this.$store.state.announcementFlag == true ||
      this.$store.state.announcementFlag == 'true'
    ) {
      axios.post('/announcement/current').then((response) => {
        if (response.data) {
          this.announcementContent = response.data;
          this.announcementSize = this.announcementContent.length;
          if (this.announcementSize > 0) {
            this.announcement = true;
          } else {
            this.$store.commit('ackAnnouncement');
          }
        } else {
          this.$store.commit('ackAnnouncement');
        }
      });
    }

    this.getIdleTime();
    this.getRevision();
  },
  methods: {
    convertDate(raw) {
      let locale = 'en-SG';
      let options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour12: false,
        hour: 'numeric',
        minute: 'numeric'
      };
      if (raw != null) {
        return new Date(raw).toLocaleString(locale, options);
      } else {
        return '-';
      }
    },
    copyLink() {
      this.linkCopied = true;

      try {
        navigator.clipboard.writeText(this.shareLink); // only works on https or localhost
      } catch (e) {
        let doc = document.getElementById('sharelinkarea');
        doc.focus();
        doc.select();
        document.execCommand('copy'); // works on http but deprecated, using this because dev server still on http
      }

      setTimeout(() => (this.linkCopied = false), 200);
    },
    checkIfItPossibleToShareView() {
      // Check If filter applied.?
      if (this.filterNotApplied) {
        this.warningFilter = this.filterNotApplied;
        // if yes, then check if stock selected on main report
      } else if (this.shareViewAvailable) {
        this.shareView();
      } else {
        this.forbiddenToShareView = true;
      }
    },
    shareView() {
      let tenantId = this.$store.state.principal.tenantId;

      if (tenantId != null) {
        let params =
          'tickerID=' +
          escape(this.$store.state.shareTicker) +
          '&year=' +
          this.$store.state.shareYear +
          '&filter=' +
          encodeURIComponent(JSON.stringify(this.$store.state.shareFilter)) +
          '&keyword=' +
          escape(this.$store.state.shareKeyword) +
          '&tenantId=' +
          tenantId +
          '&exchange_countryID=' +
          this.$store.state.shareRegion +
          '&exchange_country=' +
          this.$store.state.shareRegionName;

        this.showSL = true;

        let url = window.location.href.split('?')[0]; // remove all parameters from current url if any
        let link = CryptoJS.AES.encrypt(params, tenantId).toString();
        this.$store.state.shareTicker;
        link = this.url_encode(link);

        this.shareLink = url + '?share=' + link;
      }
    },
    url_encode(str) {
      let modified = str.replaceAll(/\+/g, '.');
      modified = modified.replaceAll(/\//g, '_');
      modified = modified.replaceAll(/=/g, '-');

      return modified;
    },
    url_decode(str) {
      let modified = str.replaceAll(/\./g, '+');
      modified = modified.replaceAll(/_/g, '/');
      modified = modified.replaceAll(/-/g, '=');

      return modified;
    },
    sendLinkChangePassword() {
      axios
        .post('/auth/forgotPassword', {
          email: this.$store.state.principal.email
        })
        .then((response) => {
          if (response.status == 200) {
            this.updatePasswordDialog = false;
          }
        });
    },
    logout() {
      this.$store
        .dispatch('logout')
        .then(() => {
          this.$router.push('/');
        })
        .catch(() => {});
    },
    showShareLink() {
      if (!this.showSL) {
        this.showSL = true;
      }
    },
    announcementValidation(item) {
      axios.post('/announcement/acknowledge', { id: item.id });
      this.announcementSize--;
      item.content = null;
      if (this.announcementSize == 0) {
        this.$store.commit('ackAnnouncement');
        this.announcement = false;
        this.$forceUpdate;
      }
    },
    getIdleTime() {
      axios.post('/setting/idleTime').then((response) => {
        this.idleTime = parseInt(response.data.value);
      });
    },
    getRevision() {
      axios.get('/dashboard/revision').then((response) => {
        this.revisionDate = response.data.revisionDate;
      });
    },
    closeNavigationPopup() {
      this.showNavigationPopup = false;
    },
    openSubscriptionDetailDialog() {
      this.$refs.subscriptionDetailDialog.openSubscriptionDetailDialog();
    }
  }
};
</script>
