import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import fastspring from './fastspring';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

/* eslint-disable */

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: ({
    principal,
    verification,
    announcementFlag,
    filters,
    watchlists,
    fastspring
  }) => ({
    principal,
    verification,
    announcementFlag,
    filters,
    watchlists,
    fastspring: {
      subscription: fastspring.subscription,
      fastspringSubscriptionData: fastspring.fastspringSubscriptionData
    }
  })
});

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    principal: { administrator: false },
    verification: {
      verifyType: 0,
      email: null,
      isSupportVarify: false,
      errorMessage: null
    },
    announcementFlag: true,
    splashScreen: false,
    notification: {
      title: null,
      content: {},
      show: false,
      code: null,
      urlPath: ''
    },
    popups: [],
    popupsMap: {},
    filters: [],
    watchlists: [],
    shareFilter: {},
    shareKeyword: null,
    shareTicker: null,
    shareRegion: null,
    shareRegionName: null,
    shareYear: null,
    isShareUrl: false,
    shareViewLink: null
  },
  mutations: {
    login(state, principal) {
      state.principal = principal;
      state.announcementFlag = true;
    },
    loginv2(state, principal) {
      state.principal = principal;
    },
    verification(state, principal) {
      state.verification = principal;
    },
    verifyType(state, principal) {
      state.verification.verifyType = principal;
    },
    agreement(state) {
      state.principal.isAgree = true;
      state.splashScreen = true;
    },
    logout(state) {
      state.principal = { administrator: false };
      state.fastspring.subscription = null;
    },
    showNotification(state, { title, content, code, urlPath }) {
      state.notification.title = title;
      state.notification.content = content;
      state.notification.code = code;
      state.notification.show = true;
      state.notification.urlPath = urlPath;
    },
    hideNotification(state) {
      state.notification.show = false;
      setTimeout(() => {
        state.notification.title = null;
        state.notification.code = null;
        state.notification.content = {};
      }, 100);
    },
    fillPopups(state, popups) {
      state.popups = structuredClone(popups);
      state.popups.forEach((item) => {
        state.popupsMap[item.popupID] = item.popupName;
      });
    },
    saveFilter(state, filter) {
      if (filter) {
        let index = state.filters.findIndex((x) => x.name == filter.name);
        if (index >= 0) {
          state.filters.splice(index, 1, filter);
        } else {
          state.filters.push(filter);
        }
      }
    },
    deleteFilter(state, name) {
      if (name) {
        let index = state.filters.findIndex((x) => x.name == name);
        if (index >= 0) {
          state.filters.splice(index, 1);
        }
      }
    },
    saveWatchlist(state, watchlist) {
      if (watchlist) {
        let index = state.watchlists.findIndex((x) => x.name == watchlist.name);
        if (index >= 0) {
          state.watchlists.splice(index, 1, watchlist);
        } else {
          state.watchlists.push(watchlist);
        }
      }
    },
    deleteWatchlist(state, name) {
      if (name) {
        let index = state.watchlists.findIndex((x) => x.name == name);
        if (index >= 0) {
          state.watchlists.splice(index, 1);
        }
      }
    },
    fillShareFilter(state, filter) {
      state.shareFilter = filter;
    },
    fillShareKeyword(state, keyword) {
      state.shareKeyword = keyword;
    },
    fillShareTicker(state, ticker) {
      state.shareTicker = ticker;
    },
    fillShareRegion(state, region) {
      state.shareRegion = region;
    },
    fillShareRegionName(state, region) {
      state.shareRegionName = region;
    },
    fillShareYear(state, year) {
      state.shareYear = year;
    },
    updateShareStatus(state, condition) {
      state.isShareUrl = condition;
    },
    updateShareViewLink(state, link) {
      state.shareViewLink = link;
    },
    ackAnnouncement(state) {
      state.announcementFlag = false;
    }
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/login', {
            email: data.email,
            password: data.password,
            recaptchaToken: data.recaptchaToken
          })
          .then((response) => {
            let principal = response.data;
            principal.password = data.password;
            principal.recaptchaToken = data.recaptchaToken;
            if (response.status == 200) {
              commit('login', principal);
              resolve({ response: response, principal: principal });
            }
          })
          .catch((error) => reject(error));
      });
    },
    loginv2({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/loginv2', {
            email: data.email,
            password: data.password,
            recaptchaToken: data.recaptchaToken
          })
          .then((response) => {
            let principal = {};
            principal.email = data.email;
            principal.password = data.password;
            principal.recaptchaToken = data.recaptchaToken;
            if (response.status == 200) {
              commit('loginv2', principal);
              commit('verification', response.data);
              resolve({ response, principal });
            }
          })
          .catch((error) => reject(error));
      });
    },
    mfaSignInv2({ commit, state, dispatch }, principal) {
      return new Promise((resolve, reject) => {
        axios
          .post('/otp/validateOtp', {
            otpnum: principal.code,
            email: state.principal.email
          })
          .then((response) => {
            let principal = response.data;
            if (response.status == 200) {
              commit('login', principal);
              resolve({ response, principal });
            }
          })
          .catch((error) => reject(error));
      });
    },
    prepareOtp({ commit, state }, principal) {
      return new Promise((resolve, reject) => {
        if (principal.sendType === 1) {
          axios
            .post('/otp/sendOtpEmail', {
              email: state.principal.email,
              password: state.principal.password,
              recaptchaToken: principal.token
            })
            .then((response) => {
              commit('verifyType', principal.sendType);
              resolve({ response: response });
            })
            .catch((error) => reject(error));
        } else if (principal.sendType === 2) {
          axios
            .post('/otp/sendOtpSms', {
              email: state.principal.email,
              password: state.principal.password,
              recaptchaToken: principal.token
            })
            .then((response) => {
              commit('verifyType', principal.sendType);
              resolve({ response: response });
            })
            .catch((error) => reject(error));
        } else {
          reject('Type error');
        }
      });
    },
    mfaSignIn({ commit, state }, principal) {
      let formData = {};
      // mfa sign in
      if (state.principal.mfaPendingCredential != null) {
        formData.code = principal.code;
        formData.mfaPendingCredential = state.principal.mfaPendingCredential;
        formData.mfaInfo = state.principal.mfaInfo;
        formData.sessionInfo = state.principal.sessionInfo;
        formData.tenantId = state.principal.tenantId;
      }
      // mfa enrollment
      else {
        formData.code = principal.code;
        formData.idToken = state.principal.idToken;
        formData.displayName = state.principal.displayName;
        formData.tenantId = state.principal.tenantId;
        formData.phoneNumber = state.principal.phoneNumber;
        formData.sessionInfo = state.principal.sessionInfo;
      }
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/mfaSignIn', formData)
          .then((response) => {
            let principal = response.data;
            if (response.status == 200) {
              commit('login', principal);
              resolve({ response: response, principal: principal });
            }
          })
          .catch((error) => reject(error));
      });
    },
    setNewPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        const { token, newPassword, tenantId, recaptchaToken } = data || {};

        axios
          .post('/tenant/new-password', {
            token,
            newPassword,
            tenantId,
            recaptchaToken
          })
          .then((response) => {
            let principal = response.data;

            if (response.status == 200) {
              commit('login', principal);
              resolve({ response, principal });
            }
          })
          .catch((error) => reject(error));
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/logout')
          .then((response) => {
            if (response.status == 200) {
              commit('logout');
              resolve({ response });
            }
          })
          .catch((error) => reject(error));
      });
    }
  },
  getters: {
    isAuthenticated: (state) =>
      state?.principal?.idToken && state?.principal?.isAgree,
    getPopups: function (state) {
      return state.popups;
    },
    getPopupsMap: function (state) {
      return state.popupsMap;
    },
    principal(state) {
      return state.principal;
    }
  },
  modules: {
    fastspring
  }
});
